$(function() {

  $('#cont-fproducto').on('show.bs.modal', function (e) {
    var form = $(this).find('form');
    form[0].reset();
    var idProducto = $(this).find('form input[name="id_producto"]');
    var tituloForm = $(this).find('form h3#titulo-form');    
    idProducto.val($(e.relatedTarget).data('idProducto'));
    tituloForm.html($(e.relatedTarget).data('titulo'));
    $(".alert").addClass('hidden');
  });

  $('#cont-fdemo').on('show.bs.modal', function (e) {
    var form = $(this).find('form');
    form[0].reset();    
    var asunto = $(this).find('form input[name="asunto"]');
    asunto.val($(e.relatedTarget).data('asunto'));
    $(".alert").addClass('hidden');
  });

  $('#cont-fsoporte').on('show.bs.modal', function (e) {
    var form = $(this).find('form');
    form[0].reset();    
    $(".alert").addClass('hidden');
  });

  if ($('#cont-fproducto')[0] != undefined) {
    $('#cont-fproducto').find('form').parsley().on("form:submit", function(){
      sendMail($('#cont-fproducto form'), 
        function(data){
          console.log('respuesta ajax', data);
          $(".alert").addClass('hidden');         
          $(".alert.alert-success").removeClass('hidden');
          window.location = $('body').data('redirect');
        },
        function(data){
          $(".alert").addClass('hidden');
          $(".alert.alert-danger").removeClass('hidden');
          errors = data.responseJSON;     
          $.each( errors , function( key, value ) {
            console.log('Errores', value[0]);
          });
        }
        );
      return false;
    });
  }

  if ($('#cont-fdemo')[0] != undefined) {
    $('#cont-fdemo').find('form').parsley().on("form:submit", function(){
      
      sendMail($('#cont-fdemo form'), 
        function(data){
          console.log('respuesta ajax', data);
          $(".alert").addClass('hidden');
          $(".alert.alert-success").removeClass('hidden');
          window.location = $('body').data('redirect');
        },
        function(data){
          $(".alert").addClass('hidden');
          $(".alert.alert-danger").removeClass('hidden');
          errors = data.responseJSON;     
          $.each( errors , function( key, value ) {
            console.log('Errores', value[0]);
          });
        }
        );

      return false; 
    });
  }

  if ($('#cont-fsoporte')[0] != undefined) {
    $('#cont-fsoporte').find('form').parsley().on("form:submit", function(){
      sendMail($('#cont-fsoporte form'), 
        function(data){
          console.log('respuesta ajax', data);
          $(".alert").addClass('hidden');         
          $(".alert.alert-success").removeClass('hidden');
          //window.location = $('body').data('redirect');
        },
        function(data){
          $(".alert").addClass('hidden');
          $(".alert.alert-danger").removeClass('hidden');
          errors = data.responseJSON;     
          $.each( errors , function( key, value ) {
            console.log('Errores', value[0]);
          });
        }
        );
      return false;
    });
  }
  

  

  function sendMail(form, fsuccess, ferror){
    //console.log('datos del form',form.serialize());
    //var form = $(this)[0];
    var formData = new FormData(form[0]);
    var route = form.attr('action');

    $.ajax({
      url: route,
      type: 'POST',
      contentType: false,
      processData: false,
      data: formData,
      beforeSend: function(){
        $(".alert").addClass('hidden');
        $(".alert.alert-warning").removeClass('hidden');
        form.find('input[type="submit"]').prop('disabled', true);
      },
      success: fsuccess,
      error: ferror,
      complete: function(){
        console.log('complete ajax');
        form.find('input[type="submit"]').removeAttr('disabled');
        form[0].reset();
      }
    });
    
  }

  $('#cont-fdemo').on('show.bs.modal', function (e) {
  console.log('test modal: ', $(e.relatedTarget).data());
  });

  acordeon();  
  
  $('.post-body iframe[src*="youtube.com"]').each(function() {
    $(this).parent("p").append($("<div></div>").addClass("flex-video").append($(this)));
  });
  

  $('body').on('change', 'select#asunto', function(event) {
    event.preventDefault();
    var electo = $(this).find(':selected').val();
    if (electo == 'Solicitud Demo' || electo == 'Cotización') {
      $('#cont-unidades').slideDown();
      $('#cont-unidades select').removeAttr('disabled');
    }else{
      $('#cont-unidades').slideUp();
      $('#cont-unidades select').attr('disabled', true);
    }
  });
var once = false;
  /*$('body').on('click', '.google-play', function(event) {
    //event.preventDefault();
    console.log('me diste click')
    //ga('send', 'event', 'Cliks', 'Click google play', 'Link a Google play', 1);
  });*/
  
  $('body').on('click', 'button.close', function(event) {
    event.preventDefault();
    $(this).parent().fadeOut();
  });

  $('body').on('submit', 'form.form-subscribe', function(event) {
    event.preventDefault();
    var form = $(this)[0];
    var formData = new FormData(form);
    var route = $(this).attr('action');
    $.ajax({
      url: route,
      type: 'POST',
      contentType: false,
      processData: false,
      data: formData,
      beforeSend: function(){
        $('i.hidden-field').fadeIn();
        $(".form-subscribe input").attr('disabled', true);
        $('.alert p').remove();
        $('.alert').slideUp();
      },
      success: function(data){
        $('.alert').slideUp();
        $('.cont-success').append('<p><i class="fa fa-check-circle">&nbsp;</i>'+data.mensaje+'</p>').slideDown();
      },
      error: function(data) {
        errors = data.responseJSON;
        $('.alert').slideUp();
        $.each( errors , function( key, value ) {
          $('.cont-error').append('<p><i class="fa fa-exclamation-circle">&nbsp;</i>'+value[0]+'</p>').slideDown();
          console.log(value[0]);
        });
      },
      complete: function(){
        $('i.hidden-field').fadeOut();
        $(".form-subscribe input").attr('disabled', false);
        $('.form-subscribe')[0].reset();
      }
    });

  });

/*Funciones Social Share Kit Redes sociales*/
  if ($('section').data('social') === undefined) {
    SocialShareKit.init();
    console.log($('#servicios').data('social'), 'mostrar btn sociales');
  }else{
    $('#ssk-movil').fadeOut();
  }
  
  
  $('body').on('click', '.btn-chat', function(event) {
    event.preventDefault();
    $zopim.livechat.window.show();
  });
  $('body').on('click', '.btn-share', function(event) {
    event.preventDefault();
    $('#ssk-movil').toggleClass('active');
  });

  /*Funciones Secuence slider*/
  var sequenceElement = $("#sequence")[0];
  if (sequenceElement!= undefined) {
    var options = {
      keyNavigation: true,
      phaseThreshold: false,
      preloader: true,
      fadeStepWhenSkipped: true,
      reverseWhenNavigatingBackwards: true, 
      //autoPlay: true,
      //autoPlayInterval: 6000
    }
    var mySequence = sequence(sequenceElement, options);
  }

  /*Funciones WOW animacion de elementos*/
  var wow = new WOW(
  {
    boxClass:     'wow',      
    animateClass: 'animated',
    offset:       0,          
    mobile:       false,       
    live:         true,       
    callback:     function(box) {

    },
    scrollContainer: null 
  }
  );
  wow.init();

  /*Funciones scroll*/
  smoothScroll.init({    
    speed: 600, 
    offset: 100, 
    updateURL: false,
  });

  var top = $('.show-top');
  function scroll() {
    if ($(window).scrollTop() > 700) {
      top.removeClass('hidden-field');
    } else {
      top.addClass('hidden-field');
    }
  }

  document.onscroll = scroll;

  $('.free-pdf').on('click', 'a.close', function(event) {
    event.preventDefault();
    $('.free-pdf').removeClass('active');
    $('.btn-pdf').addClass('show');
    once = true;
  });

  $('.free-pdf').on('click', '.btn-pdf', function(event) {
    event.preventDefault();
    $('.free-pdf').addClass('active');
    $(this).removeClass('show');
  });

  var waypoints = $('.call-pdf').waypoint(function(direction) {
    if (direction == 'down') {
      $('#ssk-movil').addClass('active');
      var pdfActive = $('#portada').data('pdf');
      if (!once && pdfActive !=0) {
        once = true;
          $('.free-pdf').addClass('active');
        $('.btn-pdf').removeClass('show');
      } else {
        $('.free-pdf').removeClass('active');
        $('.btn-pdf').addClass('show');
      }
    }      
  }, {
    offset: '40%'
  });

  var waypoints = $('#portada').waypoint(function() {
    if ($('#portada').data('pdf') != 0) {
      $('.free-pdf').removeClass('active');
      $('.btn-pdf').removeClass('show');
      $('#ssk-movil').removeClass('active');
    }
     console.log('data pdf', $('#portada').data('pdf'));
  });

  if ($('.count-tag')[0] != undefined) {
    $('#experiencia').waypoint(function() {
      $('.count-tag').countTo();
      console.log('contando');
    }, {
      offset: '40%'
    });
  }


   

  function initialize() {

    var geocoder = new google.maps.Geocoder();

    var address = "Calle 27 273, Montecarlo, Sin Nombre de Col 26, 97130 Mérida, Yuc., México"; 

    var infoPopup = '<div id="content"><h3 style="color:#ff761e; margin-bottom: 5px;" id="firstHeading" class="firstHeading">Vectium Sureste</h3><div id="bodyContent"><p>Calle 27 #273, Montecarlo, 97130 Mérida, Yuc. C.P. 97130</p></div></div>';

    var mapOptions = {
      zoom: 15,
      streetViewControl: false,
      
      scrollwheel: false
    };

    var mapElement = document.getElementById('map-canvas');
    map = new google.maps.Map(mapElement,mapOptions);

    if (geocoder) {
      geocoder.geocode( { 'address': address}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
            map.setCenter(results[0].geometry.location);

            var infowindow = new google.maps.InfoWindow({
              content: infoPopup
            });

            var marker = new google.maps.Marker({
              position: results[0].geometry.location,
              map: map,
              icon: "files/img/pin100.png",
              cursor: 'default', 
              //title:address
            });
            google.maps.event.addListener(marker, 'click', function() {
              //infowindow.setContent(address);
              infowindow.open(map, this);
            }); 

          } else {
            alert("No results found");
          }
        }else{
          console.log('geocoder status failed', google.maps.GeocoderStatus);
        }
      });
    }else{
      console.log('geodecoder not working');
    }
  }

  /*Funciones google maps*/
  if ($('#map-canvas')[0] != undefined) {
    console.log('iniciando mapa')
    google.maps.visualRefresh = true;
    var map;
    google.maps.event.addDomListener(window, 'load', initialize);
  }

});

//Funciones Youtube
// global variable for the player
var player, player2;

// this function gets called when API is ready to use
function onYouTubePlayerAPIReady() {
  // create the global player from the specific iframe (#video)
  player = new YT.Player('videoKanan', {
    events: {
      // call this function when player is ready to use
      'onReady': onPlayerReady
    }
  });

  player2 = new YT.Player('videoFuel', {
    events: {
      // call this function when player is ready to use
      'onReady': onPlayerReady
    }
  });
}

function onPlayerReady(event) {
  console.log('se cargo el video');
  
  $('#video-demo').on('show.bs.modal', function (event) {
    console.log('modal on');

    var button = $(event.relatedTarget); 
    var id = button.data('video');
    var modal = $(this);
    console.log('el id del video:'+id );
    modal.find('.modal-body iframe').css('display', 'none');
    modal.find('.modal-body iframe#'+id).css('display', 'block');
    (id == 'videoKanan') ? player.playVideo() : player2.playVideo();   
    
  });
  
  $("body").on('hidden.bs.modal', function (e) {
    console.log('modal off');
    player.stopVideo();
    player2.stopVideo();
  });
}




if ($('#numeros')[0] != undefined) {
    var waypoints = $('#numeros').waypoint(function() {
      bar.animate(1.0);  // Number from 0.0 to 1.0
    bar2.animate(1.0);  // Number from 0.0 to 1.0
    bar3.animate(1.0);  // Number from 0.0 to 1.0

  }, {
    offset: '40%'
  });

    var bar = new ProgressBar.Circle(circle1, {
  color: '#ff6806',
  strokeWidth: 6,
  trailWidth: 1,
  easing: 'easeInOut',
  duration: 2000,
  text: {
    autoStyleContainer: true
  },
  from: { color: '#fff', width: 1 },
  to: { color: '#ff6806', width: 4 },
  // Set default step function for all animate calls
  step: function(state, circle) {
    circle.path.setAttribute('stroke', state.color);
    circle.path.setAttribute('stroke-width', state.width);

    var value = Math.round(circle.value() * 100);
    if (value === 0) {
      circle.setText('');
    }else if (value === 100) {
      circle.setText('600+');
    }else {
      circle.setText(value);
    }

  }
});
bar.text.style.fontFamily = '"Titillium Web", sans-serif';
bar.text.style.fontSize = '5rem';


var bar2 = new ProgressBar.Circle(circle2, {
  color: '#ff6806',
  strokeWidth: 6,
  trailWidth: 1,
  easing: 'easeInOut',
  duration: 2000,
  text: {
    autoStyleContainer: true
  },
  from: { color: '#fff', width: 1 },
  to: { color: '#ff6806', width: 4 },
  // Set default step function for all animate calls
  step: function(state, circle) {
    circle.path.setAttribute('stroke', state.color);
    circle.path.setAttribute('stroke-width', state.width);

    var value = Math.round(circle.value() * 100);
    if (value === 0) {
      circle.setText('');
    } else {
      circle.setText(value + "%");
    }

  }
});
bar2.text.style.fontFamily = '"Titillium Web", sans-serif';
bar2.text.style.fontSize = '5rem';

var bar3 = new ProgressBar.Circle(circle3, {
  color: '#ff6806',
  strokeWidth: 6,
  trailWidth: 1,
  easing: 'easeInOut',
  duration: 2000,
  text: {
    autoStyleContainer: true
  },
  from: { color: '#fff', width: 1 },
  to: { color: '#ff6806', width: 4 },
  // Set default step function for all animate calls
  step: function(state, circle) {
    circle.path.setAttribute('stroke', state.color);
    circle.path.setAttribute('stroke-width', state.width);

    var value = Math.round(circle.value() * 100);
    if (value === 0) {
      circle.setText('');
    }else if (value === 100) {
      circle.setText('15,000+');
    }else {
      circle.setText(value);
    }

  }
});
bar3.text.style.fontFamily = '"Titillium Web", sans-serif';
bar3.text.style.fontSize = '4rem';
  }




  //uses classList, setAttribute, and querySelectorAll
//if you want this to work in IE8/9 youll need to polyfill these
function acordeon(){
  var d = document,
  accordionToggles = d.querySelectorAll('.js-accordionTrigger'),
  setAria,
  setAccordionAria,
  switchAccordion,
  touchSupported = ('ontouchstart' in window),
  pointerSupported = ('pointerdown' in window);
  
  skipClickDelay = function(e){
    e.preventDefault();
    e.target.click();
  }

    setAriaAttr = function(el, ariaType, newProperty){
    el.setAttribute(ariaType, newProperty);
  };
  setAccordionAria = function(el1, el2, expanded){
    switch(expanded) {
      case "true":
        setAriaAttr(el1, 'aria-expanded', 'true');
        setAriaAttr(el2, 'aria-hidden', 'false');
        break;
      case "false":
        setAriaAttr(el1, 'aria-expanded', 'false');
        setAriaAttr(el2, 'aria-hidden', 'true');
        break;
      default:
        break;
    }
  };
//function
switchAccordion = function(e) {
  console.log("triggered");
  e.preventDefault();
  var thisAnswer = e.target.parentNode.nextElementSibling;
  var thisQuestion = e.target;
  if(thisAnswer.classList.contains('is-collapsed')) {
    setAccordionAria(thisQuestion, thisAnswer, 'true');
  } else {
    setAccordionAria(thisQuestion, thisAnswer, 'false');
  }
    thisQuestion.classList.toggle('is-collapsed');
    thisQuestion.classList.toggle('is-expanded');
    thisAnswer.classList.toggle('is-collapsed');
    thisAnswer.classList.toggle('is-expanded');
  
    thisAnswer.classList.toggle('animateIn');
  };
  for (var i=0,len=accordionToggles.length; i<len; i++) {
    if(touchSupported) {
      accordionToggles[i].addEventListener('touchstart', skipClickDelay, false);
    }
    if(pointerSupported){
      accordionToggles[i].addEventListener('pointerdown', skipClickDelay, false);
    }
    accordionToggles[i].addEventListener('click', switchAccordion, false);
  }
};